import { createContext, useCallback, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_TAGS } from '../../definitions/queries';
import { CREATE_TAG } from '../../definitions/mutations';

export const TagsContext = createContext(null);

export default function useTagsContext(user) {
  /**
   * CREATE
   */
  const [
    createTagMutation,
    { data: dataCreateTag, loading: loadingCreateTag, error: errorCreateTag },
  ] = useMutation(CREATE_TAG, {
    context: { headers: { authorization: user?.accessToken } },
    update: (
      cache,
      {
        data: {
          tag: { create: createdTag },
        },
      }
    ) => {
      const variables = {};
      const currentData = cache.readQuery({
        query: GET_TAGS,
        variables,
      });
      const currentTags = currentData?.tags?.data;
      const updatedTags = [...currentTags, createdTag];
      cache.writeQuery({
        query: GET_TAGS,
        variables,
        data: {
          tags: { data: updatedTags, __typename: 'TagsData' },
        },
      });
    },
  });

  const createTag = async (tag, pessimistic) => {
    let options = {
      variables: {
        data: tag,
      },
    };
    if (!pessimistic) {
      const formattedTag = {
        ...tag,
        color: tag?.color || '',
        creator: tag?.creator || '',
        dateCreated: tag?.dateCreated || 0,
        dateUpdated: tag?.dateUpdated || 0,
        __typename: 'Tag',
      };
      options.optimisticResponse = {
        tag: {
          create: { ...formattedTag },
          __typename: 'TagMutations',
        },
      };
    }
    createTagMutation(options);
  };

  /**
   * READ
   */
  const generateGetTagsOptions = useCallback(() => {
    return {
      context: { headers: { authorization: user?.accessToken } },
      variables: {},
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    };
  }, [user]);

  const useGetTags = () => useQuery(GET_TAGS, generateGetTagsOptions());
  const useGetTagsLazy = () => useLazyQuery(GET_TAGS, generateGetTagsOptions());

  const [
    getTags,
    { loading: loadingGetTags, error: errorGetTags, data: dataGetTags },
  ] = useGetTagsLazy();

  const indexedTags = useMemo(() => {
    const tags = dataGetTags?.tags?.data;
    if (!tags) return {};
    const results = {};
    tags.forEach((tag) => {
      if (tag && tag.id) {
        results[tag.id] = tag;
      }
    });
    return results;
  }, [dataGetTags]);

  return {
    TagsContext,
    value: {
      // CREATE
      createTag,
      createTagOutput: {
        data: dataCreateTag,
        loading: loadingCreateTag,
        error: errorCreateTag,
      },

      // READ
      getTags,
      getTagsOutput: {
        loading: loadingGetTags,
        error: errorGetTags,
        data: dataGetTags,
      },
      indexedTags,
      useGetTags,
    },
  };
}
