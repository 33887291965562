import './ResourcesView.css';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { KeyboardContext } from '../hooks/context/useKeyboardContext';
import { ResourcesContext } from '../hooks/context/useResourcesContext';
import { TagsContext } from '../hooks/context/useTagsContext';
import Iframe from 'react-iframe';
import FloatingButtons from './FloatingButtons';
import MasonryView from './MasonryView';
// import MasonryView from './MasonryView2';
import useOutsideAlerter from '../hooks/useOutsideAlerter.js';
// import mockData from './../mockData';

export const IFrameContext = createContext(null);

export default function ResourcesView() {
  const {
    getResources,
    getResourcesOutput,
    // filteredResources = mockData,
    filteredResources,
  } = useContext(ResourcesContext);
  const { getTags, getTagsOutput, indexedTags } = useContext(TagsContext);

  const [iFrameUrl, setIFrameUrl] = useState(null);
  const [iFrameShown, setIFrameShown] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIFrameShown(false));

  const {
    loading: loadingGetResources, // eslint-disable-line
    error: errorGetResources,
    data: dataGetResources, // eslint-disable-line
  } = getResourcesOutput;

  const {
    loading: loadingGetTags, // eslint-disable-line
    error: errorGetTags, // eslint-disable-line
    data: dataGetTags, // eslint-disable-line
  } = getTagsOutput;

  useEffect(() => {
    getResources();
    getTags();
  }, [getTags, getResources]);

  const { escape: escapePressed } = useContext(KeyboardContext);
  useEffect(() => {
    setIFrameShown(false);
  }, [escapePressed]);

  if (errorGetResources) return <p>Error : {errorGetResources.message}</p>;

  return (
    <div className="view">
      <IFrameContext.Provider value={{ setIFrameShown, setIFrameUrl }}>
        <>
          {filteredResources ? (
            <MasonryView
              indexedTags={indexedTags}
              resources={filteredResources || []}
            />
          ) : (
            <div style={{ marginTop: '10rem' }} className="loader"></div>
          )}
        </>
      </IFrameContext.Provider>
      <FloatingButtons refetchResources={getResources} />
      <div
        ref={wrapperRef}
        tabIndex={-1}
        className={`iframe-wrapper ${
          iFrameShown && iFrameUrl ? '' : 'invisible'
        }`}
      >
        <Iframe
          // url="https://www.epicweb.dev/the-webs-next-transition"
          url={iFrameUrl}
          // width="640px"
          // height="320px"
          width="100%"
          height="100%"
          id=""
          className=""
          // position="absolute"
          display="block"
          src={iFrameUrl}
        />
        <div className="url">
          {/* <button onClick={() => setIFrameUrl(null)}>Close</button> */}
          <a href={iFrameUrl} target="_blank" rel="noreferrer">
            {iFrameUrl}
          </a>
        </div>
      </div>
    </div>
  );
}
