import './ResourceCard.css';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuthContext } from '../App';
import { KeyboardContext } from '../hooks/context/useKeyboardContext';
// import { IFrameContext } from './ResourcesView';
// import Iframe from 'react-iframe';
import { MdDeleteOutline } from 'react-icons/md';
import {
  HiCheckCircle,
  HiLink,
  HiOutlineCheckCircle,
  // HiOutlineSearchCircle,
} from 'react-icons/hi';
import { HiArrowPath } from 'react-icons/hi2';
import { AiOutlinePushpin, AiFillPushpin } from 'react-icons/ai';
import config from '../config';
import { ResourcesContext } from '../hooks/context/useResourcesContext';
import { TagsContext } from '../hooks/context/useTagsContext';
import Pill from './Pill';
import { useCallback } from 'react';
import { alphaSortByProperty, generateId } from '../utils';

const { resourceTypes } = config;

const ResourceCard = memo(({ indexedTags = {}, resource = {} }) => {
  const {
    creator,
    dateCreated,
    dateUpdated, // eslint-disable-line
    description,
    id,
    finished,
    metadata,
    name,
    tags: resourceTags,
    type,
    url,
  } = resource;
  // const [metadata, setMetadata] = useState(null);
  const [cardFlipped, setCardFlipped] = useState(false);
  const [backFacePinned, setBackFacePinned] = useState(false);

  const user = useContext(AuthContext);
  // const { setIFrameShown, setIFrameUrl } = useContext(IFrameContext);
  const { deleteResource, updateResource } = useContext(ResourcesContext);

  const { deletionMode } = useContext(KeyboardContext);

  // useEffect(() => {
  //   async function getMetadata() {
  //     const fullUrl = config?.corsProxyUrl
  //       ? `${config?.corsProxyUrl}/${url}`
  //       : url;

  //     const metadata = await fetchUrlMetadata(fullUrl);
  //     setMetadata(metadata);
  //     // console.log(JSON.stringify(metadata, null, 4));
  //   }
  //   getMetadata();
  // }, [url]);

  let iconUrl;
  if (metadata && (metadata['icon'] || metadata['mask-icon'])) {
    const tempUrl = metadata['icon'] || metadata['mask-icon'];
    if (tempUrl.startsWith('http')) {
      iconUrl = tempUrl;
    } else {
      const urlObject = new URL(tempUrl, url);
      iconUrl = urlObject.href;
    }
  }

  const imageUrl = useMemo(() => getImageUrlFromMetadata(metadata), [metadata]);

  return (
    <>
      <div
        onMouseLeave={() => {
          setCardFlipped(false);
        }}
        onClick={deletionMode ? () => deleteResource(resource) : null}
        onMouseOver={() => {
          // setIFrameUrl((metadata && metadata['og:video:url']) || url);
        }}
      >
        <div
          className={`card translucid-white-background ${
            cardFlipped || backFacePinned ? 'is-flipped' : ''
          } ${deletionMode ? 'card-deletion-mode' : ''}`}
        >
          <div className="card__face card__face--front">
            <div className="card-top">
              {/* <div
                className="title"
                onClick={
                  metadata
                    ? () => {
                        console.log(
                          'metadata:' + JSON.stringify(metadata, null, 4)
                        );
                      }
                    : null
                }
              > */}
              <div
                className="title"
                onClick={() => {
                  window.open(url, '_blank');
                  if (!finished) {
                    updateResource(resource, { finished: true });
                  }
                }}
              >
                {iconUrl ? (
                  <img className="icon" src={iconUrl} alt={''} />
                ) : null}{' '}
                {name}
              </div>
              <div className="right">
                {finished ? (
                  <HiCheckCircle
                    className="icon-button"
                    onClick={() =>
                      updateResource(resource, { finished: false })
                    }
                  />
                ) : (
                  <HiOutlineCheckCircle
                    className="icon-button"
                    onClick={() => updateResource(resource, { finished: true })}
                  />
                )}
                {/* <MdOutlineRemoveRedEye */}
                {/* <HiOutlineSearchCircle
                  className="icon-button"
                  onClick={() => {
                    setIFrameUrl((current) => {
                      const newUrl =
                        (metadata && metadata['og:video:url']) || url;
                      return newUrl;
                    });
                    setIFrameShown(true);
                    if (!finished) {
                      updateResource(resource, { finished: true });
                    }
                  }}
                /> */}
                {/* <MdOpenInNew
                  className="icon-button"
                  onClick={() => {
                    window.open(url, '_blank');
                    if (!finished) {
                      updateResource(resource, { finished: true });
                    }
                  }}
                /> */}
                <MdDeleteOutline
                  className="icon-button"
                  onClick={() => deleteResource(resource)}
                />
              </div>
            </div>

            <div className="card-content">
              {metadata ? (
                <div style={{ cursor: 'pointer' }}>
                  {/* {metadata['og:video:url'] ? (
                    <Iframe
                      src={metadata['og:video:url']}
                      // width="100%"
                      // height="100%"
                      id=""
                      className="iframe"
                      // position="absolute"
                      display="block"
                    />
                  ) : ( */}
                  <>
                    {imageUrl ? (
                      <div
                        className="img-with-icons"
                        // href={url}
                        // target="_blank"
                        // rel="noreferrer"
                        onClick={() => {
                          window.open(url, '_blank');
                          if (!finished) {
                            updateResource(resource, { finished: true });
                          }
                        }}
                      >
                        <img
                          src={imageUrl}
                          alt={''}
                          loading="lazy"
                          // onClick={() => setIFrameUrl(url)}
                        />
                        <div className="icons">
                          <HiLink
                            className="icon-button"
                            style={{ zIndex: 100 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(url);
                              console.log(url);
                            }}
                          />
                          {/* <a href={url} target="_blank" rel="noreferrer">
                              <HiExternalLink className="icon-button" />
                            </a> */}
                        </div>
                      </div>
                    ) : (
                      <div style={{ minHeight: '1rem' }} />
                    )}
                  </>
                  {/* )} */}
                </div>
              ) : (
                <div style={{ minHeight: '1rem' }} />
              )}
              <DescriptionSection
                description={description}
                resource={resource}
                updateResource={updateResource}
              />
              <TagsSection
                indexedTags={indexedTags}
                resource={resource}
                resourceTags={resourceTags || []}
                updateResource={updateResource}
              />
            </div>
            {/* <p>{url}</p> */}
            <div className="card-footer">
              <div className="left-text">
                <HiArrowPath
                  className="icon-button"
                  onClick={() => setCardFlipped((value) => !value)}
                />
                <select
                  value={type}
                  onChange={(e) =>
                    updateResource(resource, { type: e.target.value })
                  }
                >
                  {resourceTypes.map((resourceType) => (
                    <option key={resourceType} value={resourceType}>
                      {resourceType}
                    </option>
                  ))}
                </select>
              </div>
              <div className="right-text">
                <i>Created </i>{' '}
                {dateCreated ? (
                  <>
                    <i>on </i>
                    {toDateOrTimeString(dateCreated) + ' '}
                  </>
                ) : null}
                <i>by</i>
                {' ' + showCreator(creator, user?.email)}
              </div>
            </div>
          </div>
          <div className="card__face card__face--back">
            {/* <div className="card-top">id: {id}</div> */}
            <div className="card-content">
              {id}
              <p>{description}</p>
              {metadata ? (
                <>
                  <ul>
                    {Object.keys(metadata)
                      .sort()
                      .map(
                        (key) =>
                          key && (
                            <li key={key} className="meta-item">
                              <b>{key}:</b> {metadata[key]}
                            </li>
                          )
                      )}
                  </ul>
                </>
              ) : (
                'No metadata'
              )}
            </div>
            {/* <div className="card-footer">
              {metadata ? (
                <p
                  className="pointer"
                  onClick={() => {
                    let sortedMetadata = {};
                    let sortedKeys = Object.keys(metadata).sort();
                    sortedKeys.forEach(
                      (key) => (sortedMetadata[key] = metadata[key])
                    );
                    console.log(
                      sortedMetadata
                        ? JSON.stringify(sortedMetadata, null, 4)
                        : null
                    );
                  }}
                >
                  log metadata
                </p>
              ) : null}
            </div> */}
          </div>
          <div className="pin-icon mirror-img">
            {backFacePinned ? (
              <AiFillPushpin
                className="icon-button"
                onClick={() => setBackFacePinned((current) => !current)}
              />
            ) : (
              <AiOutlinePushpin
                className="icon-button"
                onClick={() => setBackFacePinned((current) => !current)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
});
export default ResourceCard;

function DescriptionSection({ description, resource, updateResource }) {
  const { escape: escapePressed } = useContext(KeyboardContext);

  const divRef = useRef();
  useEffect(() => {
    divRef.current.blur();
  }, [escapePressed]);

  const handleSubmit = useCallback(
    (e) => {
      const newText = e.target.textContent;

      if (newText !== description) {
        const updates = {
          description: newText,
        };
        updateResource(resource, updates);
      }
    },
    [description, resource, updateResource]
  );

  return (
    <div className="resource-description-wrapper">
      <div
        ref={divRef}
        className="resource-description"
        contenteditable="true"
        // onFocus={(e) => {
        //   e.target.select();
        // }}
        onBlur={handleSubmit}
      >
        {description}
      </div>
    </div>
  );
}

function TagsSection({
  indexedTags,
  resource,
  resourceTags = [],
  updateResource,
}) {
  const { selectedTags, selectUnselectTag } = useContext(ResourcesContext);
  const [tagInput, setTagInput] = useState('');

  const { createTag } = useContext(TagsContext);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!tagInput || !tagInput.trim()) return;
      const formattedTagInput = tagInput.trim();
      // Find existing tag (by name)
      let existingTag = Object.values(indexedTags).find(
        (tag) => tag?.name && tag.name === formattedTagInput
      );
      if (!existingTag) {
        // Create tag if non existent (wait for response and new id and color) -> Refetch list of tags
        const newTag = {
          id: generateId(),
          name: formattedTagInput,
        };

        try {
          await createTag(newTag);
          existingTag = newTag;
        } catch (err) {
          console.log(err);
        }
      }

      if (existingTag?.id && !resourceTags.includes(existingTag.id)) {
        // Update resource tags list
        const updatedTagIds = [...resourceTags, existingTag.id];
        await updateResource(resource, {
          tags: updatedTagIds,
        });
        setTagInput('');
      }
    },
    [
      createTag,
      indexedTags,
      resource,
      resourceTags,
      setTagInput,
      tagInput,
      updateResource,
    ]
  );

  return (
    <div className="tag-pills-list">
      {resourceTags &&
        resourceTags.map((tagId) => {
          const tagData = indexedTags?.[tagId] || { id: tagId };
          return (
            <Pill
              key={tagId}
              handleClick={() => selectUnselectTag(tagId)}
              selected={selectedTags.includes(tagId)}
              data={tagData}
              resource={resource}
              resourceTags={resourceTags}
              selectedTags={selectedTags}
              size="S"
              updateResource={updateResource}
            />
          );
        })}

      <form
        autocomplete="off"
        style={{
          // display: 'flex',
          // alignItems: 'center',
          // position: 'absolute',
          margin: '0.1rem 0rem',
          width: '4rem',
          maxHeight: '2rem',
          // width: 'fit-content',
        }}
        onSubmit={handleSubmit}
      >
        <div
          className="tag-create-input-with-suggestions"
          onBlur={() => setTimeout(() => setTagInput(''), 500)}
        >
          <input
            className="tag-create-input"
            onChange={(e) => {
              const newValue = e.target.value;
              const alphanumericRegex = /^[A-Za-z0-9\s]*$/;
              const emojiRegex = /<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu;

              const isAllowed =
                alphanumericRegex.test(newValue) || emojiRegex.test(newValue);
              if (isAllowed) {
                let formattedInput = newValue.toLowerCase().trim();
                if (newValue !== ' ' && newValue.endsWith(' '))
                  formattedInput += ' ';
                setTagInput(formattedInput);
              }
            }}
            type="text"
            value={tagInput}
            placeholder="+"
            maxlength="14"
          />
          <TagSuggestions
            tagInput={tagInput}
            indexedTags={indexedTags}
            resource={resource}
            setTagInput={setTagInput}
            updateResource={updateResource}
          />
        </div>
      </form>
    </div>
  );
}

function TagSuggestions({
  indexedTags,
  resource,
  setTagInput,
  tagInput,
  updateResource,
}) {
  let suggestedTags = [];
  const resourceTags = useMemo(() => resource?.tags || [], [resource]);
  if (tagInput) {
    for (let tagId in indexedTags) {
      if (resourceTags?.includes(tagId)) continue;
      const tagData = indexedTags[tagId];
      const { name: tagName } = tagData;
      if (tagName?.includes(tagInput)) {
        suggestedTags.push(tagData);
      }
      if (suggestedTags.length > 5) break;
    }
  }
  alphaSortByProperty(suggestedTags, 'name', true);

  const handleClick = useCallback(
    async (tagId) => {
      if (!tagId) return;
      if (!resourceTags.includes(tagId)) {
        const updatedTagIds = [...resourceTags, tagId];
        await updateResource(resource, {
          tags: updatedTagIds,
        });
        setTagInput('');
      }
    },
    [resource, resourceTags, setTagInput, updateResource]
  );

  return suggestedTags.length > 0 ? (
    <div className="tag-create-input-suggestions">
      <div className="tag-pills-list">
        {indexedTags &&
          suggestedTags.map((tagData) => {
            return (
              <Pill
                key={tagData?.id}
                handleClick={() => {
                  handleClick(tagData?.id);
                }}
                data={tagData}
                size="S"
              />
            );
          })}
      </div>
    </div>
  ) : null;
}

function showCreator(creatorEmail, userEmail) {
  return !userEmail || creatorEmail !== userEmail ? creatorEmail : 'you';
}

function toDateOrTimeString(dateMs) {
  if (!dateMs) return '';

  const date = new Date(dateMs);

  const today = new Date();
  return today.toLocaleDateString() === date.toLocaleDateString()
    ? date.toLocaleTimeString()
    : date.toLocaleDateString();
}

function getImageUrlFromMetadata(metadata) {
  let url = '';
  if (!metadata) return url;
  if (metadata['og:image']) {
    if (metadata['og:image'].startsWith('/') && metadata['og:url']) {
      url += metadata['og:url'];
    }
    url += metadata['og:image'];
  } else if (metadata['mask-icon']) {
    url += metadata['mask-icon'];
  }
  return url;
}
