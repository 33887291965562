import './FloatingButtons.css';
import React, { useContext, useState, useRef, useCallback } from 'react';
import { AuthContext } from '../App';
import { ResourcesContext } from '../hooks/context/useResourcesContext';
import { HiOutlineRefresh, HiPlusSm } from 'react-icons/hi';
import { generateId } from '../utils';

// import config from '../config';
// const { resourceTypes } = config;

export default function FloatingButtons({ refetchResources }) {
  const [newResourceUrl, setNewResourceUrl] = useState('');
  const [newResourceType, setNewResourceType] = useState('LINK'); // eslint-disable-line
  const createResourceInputRef = useRef(null);
  const user = useContext(AuthContext);
  const [refreshPressed, setRefreshPressed] = useState(false);
  const [creationPanelVisible, setCreationPanelVisible] = useState(false);
  const [creationPanelSlided, setCreationPanelSlided] = useState(false);

  const { createResource, selectedTags } = useContext(ResourcesContext);

  const handleResourceCreation = useCallback(
    async (e) => {
      e.preventDefault();

      let url = newResourceUrl;
      if (!url.startsWith('http')) url = `https://${url}`;
      // const exp = /^(http(s*):\/*)*w*\.*$/;
      const exp =
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // eslint-disable-line
      if (!url.match(exp)) return;
      const urlObject = new URL(url);
      const newResource = {
        description: '',
        destination: user?.email,
        id: generateId(),
        name: urlObject.hostname,
        tags: selectedTags && selectedTags.length > 0 ? selectedTags : [],
        type: newResourceType || 'LINK',
        url,
      };

      await createResource(newResource);
      // setNewResourceUrl('https://www.');
      setNewResourceUrl('');
    },
    [
      createResource,
      newResourceType,
      newResourceUrl,
      selectedTags,
      setNewResourceUrl,
      user,
    ]
  );

  return (
    <>
      <div className="floating-buttons-section">
        <div className="refresh-resources-section">
          <div
            className={`icon-button icon-button-floating refresh-icon white-border ${
              refreshPressed ? '' : 'rotate full'
            }`}
            onClick={() => {
              refetchResources();
              setRefreshPressed((value) => {
                if (value) {
                  setTimeout(() => setRefreshPressed(true), 100);
                  return false;
                } else {
                  setTimeout(() => setRefreshPressed(false), 100);
                  return true;
                }
              });
            }}
          >
            <HiOutlineRefresh />
          </div>
        </div>
        <div className="add-resource-section">
          <div
            className={`icon-button icon-button-floating add-resource-button white-border ${
              creationPanelSlided ? 'rotate quarter' : 'rotate'
            }`}
            onClick={() => {
              setCreationPanelSlided((value) => {
                const isVisible = !value;
                if (isVisible) {
                  setTimeout(() => createResourceInputRef.current.focus(), 200);
                }
                return isVisible;
              });
            }}
            onMouseOver={() => {
              setCreationPanelVisible(true);
            }}
            onMouseLeave={() => {
              if (!creationPanelSlided) {
                setTimeout(() => setCreationPanelVisible(false), 500);
              }
            }}
          >
            <HiPlusSm />
          </div>
        </div>
      </div>
      <div
        className={`add-resource-options-wrapper-wrapper ${
          creationPanelVisible ? `visible` : ''
        }`}
      >
        <div className="add-resource-options-wrapper">
          <form
            className={`add-resource-options  ${
              creationPanelSlided ? `slide` : ''
            }`}
            onSubmit={async (e) => {
              await handleResourceCreation(e);
            }}
          >
            <div className="input-with-icons">
              <input
                ref={createResourceInputRef}
                type="text"
                value={newResourceUrl}
                onChange={(e) => setNewResourceUrl(e.target.value)}
                onClick={() =>
                  // setNewResourceUrl((value) => value || 'https://www.')
                  setNewResourceUrl((value) => value)
                }
              />
              <div className="input-icons no-border">
                {/* <MdContentPaste
                className="icon-button"
                onClick={async () => {
                  const clipboardText = await navigator.clipboard.readText();
                  setNewResourceUrl(clipboardText);
                  createResourceInputRef.current.focus();
                }}
              /> */}
                {/* <button className="invisible-button no-border" type="submit">
                  {' '}
                  <MdArrowForward type="submit" className="icon-button" />
                </button> */}
              </div>
            </div>
            {/* <select
              name="newResourceType"
              value={newResourceType}
              onChange={(e) => {
                setNewResourceType(e.target.value);
                createResourceInputRef.current.focus();
              }}
            >
              {resourceTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select> */}
          </form>
        </div>
      </div>
    </>
  );
}
