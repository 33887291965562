// import { HiCheckCircle } from 'react-icons/hi';
// import { HiArrowUturnLeft } from 'react-icons/hi2';
import { MdOutlineRefresh } from 'react-icons/md';
import './UndoRedo.css';
import { UndoRedoContext } from '../hooks/context/useUndoRedoContext';
import { useContext } from 'react';
import useDebouncedState from '../hooks/useDebouncedState';
// import { KeyboardContext } from '../hooks/context/useKeyboardContext';

export default function UndoRedo() {
  const {
    // actionIndex,
    canRedo,
    canUndo,
    // executedActions,
    redo,
    undo,
  } = useContext(UndoRedoContext);

  const debouncedCanRedo = useDebouncedState(canRedo, 50, false);

  // TODO: prevent shortcuts executing multiple times when pressed
  // const { redo: redoShortcutPressed, undo: undoShortcutPressed } =
  //   useContext(KeyboardContext);

  // useEffect(() => {
  //   if (undoShortcutPressed && canUndo) {
  //     undo();
  //   }
  // }, [canUndo, undo, undoShortcutPressed]);

  // useEffect(() => {
  //   if (redoShortcutPressed && canRedo) {
  //     redo();
  //   }
  // }, [canRedo, redo, redoShortcutPressed]);

  return (
    <>
      {/* <div style={{ position: 'fixed', left: '2rem', bottom: '10rem' }}>
        <div>actionIndex: {actionIndex.toString()}</div>
        <div>canUndo: {canUndo.toString()}</div>
        <div>canRedo: {canRedo.toString()}</div>
      </div> */}
      <div className="undo-redo-section">
        <MdOutlineRefresh
          className={`icon-button mirror-img ${
            canUndo ? '' : ' disabled-button'
          }`}
          onClick={() => undo()}
        />
        <MdOutlineRefresh
          className={`icon-button ${
            debouncedCanRedo ? '' : ' disabled-button'
          }`}
          onClick={() => redo()}
        />
      </div>
    </>
  );
}
