import { useCallback, useEffect, useState } from 'react';

const shortcuts = {
  deletionMode: ['Shift', 'Control', 'D'],
  escape: ['Escape'],
  redo: ['Shift', 'Control', 'z'],
  search: ['Shift', 'Control', 'F'],
  undo: ['Control', 'z'],
};

export default function useKeyboardShortcuts(pressedKeys) {
  const [deletionMode, setDeletionMode] = useState(false);
  const [escape, setEscape] = useState(false);
  const [redo, setRedo] = useState(false);
  const [search, setSearch] = useState(false);
  const [undo, setUndo] = useState(false);

  const isShortcutPressed = useCallback(
    (shortcut) =>
      shortcut.reduce((acc, key) => acc && pressedKeys.includes(key), true),
    [pressedKeys]
  );

  useEffect(() => {
    setDeletionMode(isShortcutPressed(shortcuts['deletionMode']));
    setEscape(isShortcutPressed(shortcuts['escape']));
    setRedo(isShortcutPressed(shortcuts['redo']));
    setSearch(isShortcutPressed(shortcuts['search']));
    setUndo(isShortcutPressed(shortcuts['undo']));
  }, [isShortcutPressed]);

  return { deletionMode, escape, redo, search, undo };
}
