import { signInWithGoogle } from '../../services/firebase';
import { FcGoogle } from 'react-icons/fc';

import '../../App.css';

const GoogleLogin = () => {
  return (
    <div>
      <button className="login-button" onClick={signInWithGoogle}>
        <FcGoogle size={'1.2rem'} />
        Sign in with Google
      </button>
    </div>
  );
};

export default GoogleLogin;
