import { v4 as uuidv4 } from 'uuid';
import { parse } from 'node-html-parser';

export function generateId() {
  return uuidv4();
}

export function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

export function alphaSortByProperty(inputList, propertyName, inPlace) {
  const executeSort = (list) =>
    list.sort((a, b) => {
      if (a[propertyName] < b[propertyName]) {
        return -1;
      }
      if (a[propertyName] > b[propertyName]) {
        return 1;
      }
      return 0;
    });

  let inputListCopy = inputList;
  const result = inPlace ? executeSort(inputList) : executeSort(inputListCopy);
  return result;
}

export async function fetchUrlMetadata(url) {
  try {
    const html = await (
      await fetch(url, {
        timeout: 5000,
        cache: 'force-cache',
        headers: {
          'User-Agent': 'request',
        },
      })
    ).text();

    const root = parse(html);

    let metadata = {};
    const metas = root.querySelectorAll('meta');

    metas.forEach((meta) => {
      const attrs = meta?.attrs;
      const key = attrs?.name || attrs?.property;
      const content = attrs?.content;
      metadata[key] = content;
    });

    const links = root.querySelectorAll('link');
    links.forEach((link) => {
      const attrs = link?.attrs;
      const key = attrs?.rel;
      if (key === 'icon' || key === 'mask-icon') {
        const href = attrs?.href;
        metadata[key] = href;
      }
    });

    return metadata;
  } catch (err) {
    throw err;
  }
}
