import './Sidebar.css';
import Pill from './Pill';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ResourcesContext } from '../hooks/context/useResourcesContext';
import { TagsContext } from '../hooks/context/useTagsContext';
import { alphaSortByProperty } from '../utils';
// import {
//   TbLayoutSidebarLeftCollapse,
//   TbLayoutSidebarLeftExpand,
// } from 'react-icons/tb';
import {
  IoChevronBackCircleOutline,
  IoChevronForwardCircleOutline,
} from 'react-icons/io5';
import useWindowDimensions from '../hooks/useWindowDimensions.js';
// import {
//   MdCircle,
//   MdOutlineInventory2,
//   MdLibraryBooks,
//   MdOutlineVideoLibrary,
// } from 'react-icons/md';

// import { HiCheckCircle, HiOutlineCheckCircle } from 'react-icons/hi';
// import { UndoRedoContext } from '../hooks/context/useUndoRedoContext';

function Sidebar() {
  const {
    filteredResources,
    getResourcesOutput,
    mostRecentTags,
    // setResourceTypesOptions,
    // setPendingOption,
    // setFinishedOption,
    // selectedFilters,
    // setSelectedFilters,
    selectedTags,
    setSelectedTags,
    selectUnselectTag,
  } = useContext(ResourcesContext);
  const { indexedTags } = useContext(TagsContext);
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const { width: screenWidth } = useWindowDimensions();
  // const { executedActions } = useContext(UndoRedoContext);

  // const [selectedType, setSelectedType] = useState('');
  // const [selectedStatus, setSelectedStatus] = useState('PENDING');

  // const selectedTags = selectedFilters?.tags || [];
  // const setSelectedTags = (updatedTags) =>
  //   setSelectedFilters((currentSelectedFilters) => ({
  //     ...currentSelectedFilters,
  //     tags: updatedTags,
  //   }));

  // const [selectedTags, setSelectedTags] = useState([]);

  // useEffect(() => {
  //   setResourceTypesOptions((resourceTypesOptions) => {
  //     if (!selectedType) return {};
  //     return {
  //       // ...resourceTypesOptions,
  //       [selectedType]: true,
  //     };
  //   });
  // }, [selectedType, setResourceTypesOptions]);

  // useEffect(() => {
  //   switch (selectedStatus) {
  //     case 'PENDING':
  //       setFinishedOption(false);
  //       setPendingOption(true);
  //       break;
  //     case 'FINISHED':
  //       setFinishedOption(true);
  //       setPendingOption(false);
  //       break;
  //     default:
  //       setFinishedOption(false);
  //       setPendingOption(false);
  //   }
  // }, [selectedStatus, setFinishedOption, setPendingOption]);

  // const handleClick = useCallback(
  //   (tagValue) => {
  //     const index = selectedTags.indexOf(tagValue);
  //     const updatedTagsList = [...selectedTags];
  //     if (index === -1) {
  //       updatedTagsList.push(tagValue);
  //     } else {
  //       updatedTagsList.splice(index, 1);
  //     }
  //     setSelectedTags(updatedTagsList);
  //   },
  //   [setSelectedTags]
  // );

  useEffect(() => {
    if (screenWidth < 1000) {
      setSidebarHidden(true);
    } else {
      setSidebarHidden(false);
    }
  }, [screenWidth]);

  const {
    loading: loadingGetResources, // eslint-disable-line
    error: errorGetResources, // eslint-disable-line
    data: dataGetResources,
  } = getResourcesOutput;

  const resources = useMemo(
    () => dataGetResources?.resources?.data,
    [dataGetResources]
  );

  const usedTags = useMemo(() => {
    let tagIdsList = extractTagIdsFromResources(resources);

    // Unselect tags that are not in the new list
    if (resources && resources?.length > 0) {
      for (let selectedTag of selectedTags) {
        if (!tagIdsList.includes(selectedTag)) {
          selectUnselectTag(selectedTag);
        }
      }
    }
    const resultsList = [];
    tagIdsList.forEach((tagId) => {
      const tagData = indexedTags?.[tagId];
      if (tagId && tagData) {
        resultsList.push(tagData);
      }
    });
    alphaSortByProperty(resultsList, 'name', true);
    return resultsList;
  }, [indexedTags, resources, selectedTags, selectUnselectTag]);

  const usedTagsInFilteredResources = useMemo(
    () => extractTagIdsFromResources(filteredResources),
    [filteredResources]
  );

  return (
    <>
      {sidebarHidden ? (
        <IoChevronForwardCircleOutline
          className="icon-button sidebar-icon"
          size={22}
          onClick={() => {
            setSidebarHidden((current) => !current);
          }}
        />
      ) : (
        <IoChevronBackCircleOutline
          className="icon-button sidebar-icon"
          size={22}
          onClick={() => {
            setSidebarHidden((current) => !current);
          }}
        />
      )}

      <div
        className={`sidebar-container ${sidebarHidden ? 'hidden-sidebar' : ''}`}
      >
        {/* <p>{JSON.stringify(selectedFilters, null, 4)}</p> */}
        {/* <p>{JSON.stringify(filteredResources, null, 4)}</p> */}
        {/* <b>Resources:</b>
      {resources && resources.map((resource) => <div>{resource.name}</div>)}
      <br /> */}
        {/* <b>Tags:</b>
      {usedTags && usedTags.map((tag) => <div>{tag}</div>)} */}

        {/* <Pill
        text="All"
        Icon={MdCircle}
        onClick={() => setSelectedType('')}
        selected={selectedType === ''}
      />
      <Pill
        text="Read"
        Icon={MdLibraryBooks}
        onClick={() => setSelectedType('READ')}
        selected={selectedType === 'READ'}
      />
      <Pill
        text="Watch"
        Icon={MdOutlineVideoLibrary}
        onClick={() => setSelectedType('WATCH')}
        selected={selectedType === 'WATCH'}
      />
      <Pill
        text="Stored"
        Icon={MdOutlineInventory2}
        onClick={() => setSelectedType('STORE')}
        selected={selectedType === 'STORE'}
      />
      <br />
      <br />
      <Pill
        text="All"
        Icon={MdCircle}
        onClick={() => setSelectedStatus('')}
        selected={selectedStatus === ''}
      />
      <Pill
        text="Pending"
        Icon={HiOutlineCheckCircle}
        onClick={() => setSelectedStatus('PENDING')}
        selected={selectedStatus === 'PENDING'}
      />
      <Pill
        text="Finished"
        Icon={HiCheckCircle}
        onClick={() => setSelectedStatus('FINISHED')}
        selected={selectedStatus === 'FINISHED'}
      /> */}
        {/* {selectedTags && <p>{JSON.stringify(selectedTags)}</p>} */}
        <br />
        {/* {usedTags && usedTags.length > 0 && selectedTags?.length === 0 && ( */}
        {usedTags && usedTags.length > 0 && (
          <div className="pills-container">
            {usedTags.map((tagData) => (
              <Pill
                key={tagData?.id}
                handleClick={() => selectUnselectTag(tagData?.id)}
                selected={selectedTags.includes(tagData?.id)}
                size={
                  mostRecentTags && mostRecentTags.includes(tagData?.id)
                    ? 'B'
                    : 'S'
                }
                data={tagData || {}}
              />
            ))}
          </div>
        )}
        {usedTags && selectedTags && selectedTags.length > 0 && (
          <>
            <br />
            <button
              className="clear-selection-button"
              onClick={() => setSelectedTags([])}
            >
              x Clear selection
            </button>
            <br />
          </>
        )}
        <br />
        {selectedTags &&
          selectedTags.length > 0 &&
          usedTagsInFilteredResources &&
          usedTagsInFilteredResources.length > 1 &&
          filteredResources &&
          filteredResources.length > 1 && (
            <>
              <hr
                style={{
                  // width: '50%',
                  width: '80%',
                  border: 0,
                  height: 0,
                  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
                  // paddingRight: '0.2rem',
                  marginLeft: '1.2rem',
                  marginBottom: '0.4rem',
                }}
              />
              <br />
              <div className="pills-container">
                {usedTagsInFilteredResources.map((tagId) => (
                  <Pill
                    key={tagId}
                    handleClick={() => selectUnselectTag(tagId)}
                    selected={selectedTags.includes(tagId)}
                    size="S"
                    data={indexedTags?.[tagId] || {}}
                  />
                ))}
                {/* {usedTagsInFilteredResources.map((tagId) =>
                !selectedTags.includes(tagId) ? (
                  <Pill
                    key={tagId}
                    handleClick={() => selectUnselectTag(tagId)}
                    selected={selectedTags.includes(tagId)}
                    size="S"
                    data={indexedTags?.[tagId] || {}}
                  />
                ) : null
              )} */}
              </div>
            </>
          )}
        {/* <Pill
        handleClick={() => handleClick('finished')}
        Icon={HiCheckCircle}
        selected={selectedTags.includes('finished')}
        value="finished"
      />
      <Pill
        handleClick={() => handleClick('pending')}
        Icon={MdOutlineVideoLibrary}
        selected={selectedTags.includes('pending')}
        value="pending"
      /> */}
        {/* {executedActions && (
        <div>
          {executedActions.map((action) => (
            <div>{action?.name}</div>
          ))}
        </div>
      )} */}
      </div>
    </>
  );
}

const extractTagIdsFromResources = (resourcesList) => {
  const resultsSet = new Set();
  if (resourcesList) {
    resourcesList.forEach((resource) => {
      const resourceTags = resource?.tags;
      if (resourceTags && resourceTags.length > 0) {
        resourceTags.forEach((tag) => resultsSet.add(tag));
      }
    });
  }
  return Array.from(resultsSet).sort();
  // TODO: fix this sort. Wrong because they are ids
  // let tagIdsList = Array.from(resultsSet);
  // const resultsList = [];
  // tagIdsList.forEach((tagId) => {
  //   const tagData = indexedTags?.[tagId];
  //   if (tagId && tagData) {
  //     resultsList.push(tagData);
  //   }
  // });
  // console.log('resultsList 1:', resultsList);
  // alphaSortByProperty(resultsList, 'name', true);
  // console.log('resultsList 2:', resultsList);
  // return resultsList;
};

export default Sidebar;
