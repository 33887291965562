import ResourcesView from './ResourcesView';
import './Home.css';

const Home = () => {
  return (
    <div>
      <ResourcesView />
    </div>
  );
};

export default Home;
