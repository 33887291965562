import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdLogout } from 'react-icons/md';
import { AuthContext } from '../App';
import { KeyboardContext } from '../hooks/context/useKeyboardContext';
import { ResourcesContext } from '../hooks/context/useResourcesContext';
// import ResourcesOptions from './ResourcesOptions';
import './NavBar.css';

export default function NavBar({ signOut }) {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const user = useContext(AuthContext);
  const { setSearchInput } = useContext(ResourcesContext);
  const { search: searchShortcutPressed } = useContext(KeyboardContext);
  const [preDebounceSearchInput, setPreDebounceSearchInput] = useState('');

  const clearState = useCallback(() => setProfileMenuOpen(false), []);

  // const handleChange = (e) => {
  //   const delayDebounceFn = setTimeout(
  //     () => setPreDebounceSearchInput(e.target.value),
  //     350
  //   );
  //   // setPreDebounceSearchInput(e.target.value);
  //   return () => clearTimeout(delayDebounceFn);
  // };

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => setSearchInput(preDebounceSearchInput),
      500
    );

    return () => clearTimeout(delayDebounceFn);
  }, [preDebounceSearchInput, setSearchInput]);

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current?.select();
  }, [searchShortcutPressed]);

  return (
    <nav id="top-bar">
      <div id="top-bar-left">
        <div
          className="logo logo-outer"
          onClick={() => {
            navigator.clipboard.writeText(user?.accessToken);
            // console.log(user?.accessToken);
          }}
        >
          <div className="logo logo-inner"></div>
        </div>
      </div>
      {user ? (
        <>
          <div id="top-bar-center">
            <input
              className="resource-search-input translucid-white-background"
              maxlength="20"
              name="resource-search"
              onChange={(e) => setPreDebounceSearchInput(e.target.value)}
              // onChange={handleChange}
              onFocus={(e) => e.target.select()}
              placeholder="🧐 Search…"
              ref={inputRef}
              size="30"
              type="search"
              value={preDebounceSearchInput}
            />
          </div>
          <div id="top-bar-right">
            <img
              id="profile-button"
              onClick={() => {
                setProfileMenuOpen((currentValue) => !currentValue);
              }}
              src={user.photoURL}
              alt=""
            />
            {profileMenuOpen ? (
              <div className="profile-menu-outter">
                <div className="profile-menu">
                  <span id="user-name">{user.displayName}</span>
                  <div
                    className="profile-tile"
                    onClick={() => {
                      signOut();
                      clearState();
                    }}
                  >
                    <MdLogout />
                    Logout
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </nav>
  );
}
