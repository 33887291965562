import { createContext } from 'react';
import usePressedKey from '../usePressedKey';
import useKeyboardShortcuts from '../useKeyboardShortcuts';

export const KeyboardContext = createContext(null);

export default function useUndoRedoContext() {
  const [pressedKeys] = usePressedKey();
  const value = useKeyboardShortcuts(pressedKeys);
  return {
    KeyboardContext,
    value,
  };
}
