import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_RESOURCES } from '../../definitions/queries';
import { useMutation } from '@apollo/client';
import {
  CREATE_RESOURCE,
  DELETE_RESOURCE,
  UPDATE_RESOURCE,
} from '../../definitions/mutations';
import useLocalStorageState from '../useLocalStorageState.js';

export const ResourcesContext = createContext(null);

export default function useResourcesContext(user, indexedTags) {
  // const [resourceTypesOptions, setResourceTypesOptions] = useState({});
  const [creatorOption, setCreatorOption] = useState(null);
  // const [pendingOption, setPendingOption] = useState(true);
  // const [finishedOption, setFinishedOption] = useState(false);
  const [executedActions, setExecutedActions] = useState([]);
  const addExecutedAction = (action) =>
    setExecutedActions((current) => [...current, action]);

  const [selectedFilters, setSelectedFilters] = useLocalStorageState({
    debounce: true,
    defaultValue: () => ({
      tags: [],
      search: '',
    }),
    key: 'ltr:selected-filters',
  });

  const maxRecentTags = 3;
  const [mostRecentTags, setMostRecentTags] = useLocalStorageState({
    debounce: true,
    defaultValue: () => [],
    key: 'ltr:most-recent-tags',
  });

  const selectedTags = useMemo(
    () => selectedFilters?.tags || [],
    [selectedFilters]
  );
  const setSelectedTags = useCallback(
    (updatedTags) =>
      setSelectedFilters((currentSelectedFilters) => ({
        ...currentSelectedFilters,
        tags: updatedTags,
      })),
    [setSelectedFilters]
  );

  const selectUnselectTag = useCallback(
    (tagId) => {
      const index = selectedTags.indexOf(tagId);
      const updatedTagsList = [...selectedTags];
      if (index === -1) {
        updatedTagsList.push(tagId);
      } else {
        updatedTagsList.splice(index, 1);
      }

      setMostRecentTags((current) => {
        if (current && current.includes(tagId)) return current;
        const updatedList = [...current];
        const updatedListLength = updatedList.unshift(tagId);
        if (updatedListLength > maxRecentTags) updatedList.pop();
        return updatedList;
      });

      setSelectedTags(updatedTagsList);
    },
    [selectedTags, setMostRecentTags, setSelectedTags]
  );

  const searchInput = useMemo(() => selectedFilters?.search, [selectedFilters]);
  const setSearchInput = useCallback(
    (updatedSearchInput) =>
      setSelectedFilters((currentSelectedFilters) => ({
        ...currentSelectedFilters,
        search: updatedSearchInput,
      })),
    [setSelectedFilters]
  );

  useEffect(() => {
    if (user?.email) setCreatorOption(user.email);
  }, [user]);

  /**
   * CREATE
   */
  const [
    createResourceMutation,
    {
      data: dataCreateResource,
      loading: loadingCreateResource,
      error: errorCreateResource,
    },
  ] = useMutation(CREATE_RESOURCE, {
    context: { headers: { authorization: user?.accessToken } },
    update: (
      cache,
      {
        data: {
          resource: { create: createdResource },
        },
      }
    ) => {
      const variables = generateGetResourcesVariables();
      const currentData = cache.readQuery({
        query: GET_RESOURCES,
        variables,
      });
      const currentResources = currentData?.resources?.data;
      const updatedResources = [createdResource, ...currentResources];
      cache.writeQuery({
        query: GET_RESOURCES,
        variables,
        data: {
          resources: {
            data: updatedResources,
            __typename: 'ResourcesData',
          },
        },
      });
    },
  });

  const createResource = (resource, skipUndoRedoAddition) => {
    const optimisticResponseResource = {
      ...resource,
      creator: resource?.creator || '',
      id: resource?.id || '',
      dateCreated: resource?.dateCreated || 0,
      dateUpdated: resource?.dateUpdated || 0,
      finished: resource?.dateUpdated || false,
      metadata: resource?.metadata || null,
      tags: resource?.tags || [],
      __typename: 'Resource',
    };

    const resourceCreationInput = {
      description: resource?.description,
      destination: resource?.destination,
      finished: resource?.finished,
      id: resource?.id,
      metadata: resource?.metadata,
      name: resource?.name,
      tags: resource?.tags,
      type: resource?.type,
      url: resource?.url,
    };

    if (!skipUndoRedoAddition) {
      addExecutedAction({
        name: 'createResource',
        params: { resource: { ...optimisticResponseResource } },
      });
    }
    createResourceMutation({
      variables: {
        data: resourceCreationInput,
      },
      optimisticResponse: {
        resource: {
          create: { ...optimisticResponseResource },
          __typename: 'ResourceMutations',
        },
      },
    });
  };

  /**
   * READ
   */
  const generateGetResourcesVariables = useCallback(() => {
    const filters = {};
    const creator = creatorOption;

    // let finished;
    // if (pendingOption && !finishedOption) {
    //   finished = false;
    // } else if (!pendingOption && finishedOption) {
    //   finished = true;
    // }
    // const types = buildActiveTypesList(resourceTypesOptions);
    if (creator) filters.creator = creator;
    // if (types) filters.types = types;
    // if (finished !== undefined) filters.finished = finished;

    return {
      filters,
      order: {
        field: 'dateCreated',
        variation: 'DESC',
      },
    };
    // }, [creatorOption, finishedOption, pendingOption, resourceTypesOptions]);
  }, [creatorOption]);

  const generateGetResourcesOptions = useCallback(() => {
    return {
      context: { headers: { authorization: user?.accessToken } },
      variables: generateGetResourcesVariables(),
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    };
  }, [generateGetResourcesVariables, user]);

  const useGetResourcesLazy = () =>
    useLazyQuery(GET_RESOURCES, generateGetResourcesOptions());

  const [
    getResources,
    {
      loading: loadingGetResources,
      error: errorGetResources,
      data: dataGetResources,
    },
  ] = useGetResourcesLazy();
  // useEffect(() => {
  //   getResources();
  // }, [getResources, resourceTypesOptions]);

  const useGetResources = () =>
    useQuery(GET_RESOURCES, generateGetResourcesOptions());

  const filteredResources = useMemo(() => {
    const resources = dataGetResources?.resources?.data;
    if (!resources || resources.length < 1) return resources;

    const selectedTags = selectedFilters.tags || [];
    const searchInput = selectedFilters.search || '';
    return resources.filter((resource) => {
      let result = true;

      // Selected tags
      if (selectedTags && selectedTags.length > 0) {
        const resourceTags = resource?.tags;
        if (resourceTags && resourceTags.length > 0) {
          result = selectedTags.every((selectedTag) =>
            resourceTags.includes(selectedTag)
          );
        } else {
          result = false;
        }
      }

      // Search input
      if (searchInput) {
        const resourceUrl = resource?.url;
        const resourceTags = resource?.tags;
        // Check url matches
        result = resourceUrl?.includes(searchInput);
        if (!result && resourceTags && resourceTags.length > 0) {
          // Check tag name matches
          for (let tagId of resourceTags) {
            if (!tagId) continue;
            const tagData = indexedTags?.[tagId];
            if (tagData?.name && tagData.name.includes(searchInput)) {
              result = true;
              break;
            }
          }
        }
      }

      return result;
    });
  }, [dataGetResources, indexedTags, selectedFilters]);

  /**
   * DELETE
   */
  const [
    deleteResourceMutation,
    {
      data: dataDeleteResource,
      loading: loadingDeleteResource,
      error: errorDeleteResource,
    },
  ] = useMutation(DELETE_RESOURCE, {
    context: { headers: { authorization: user?.accessToken } },
    update(
      cache,
      {
        data: {
          resource: { delete: deletedResource },
        },
      }
    ) {
      const variables = generateGetResourcesVariables();
      const currentData = cache.readQuery({
        query: GET_RESOURCES,
        variables,
      });
      const currentResources = currentData?.resources?.data;
      const updatedResources = currentResources.filter(
        (resource) => resource.id !== deletedResource.id
      );
      cache.writeQuery({
        query: GET_RESOURCES,
        variables,
        data: {
          resources: { data: updatedResources, __typename: 'ResourcesData' },
        },
      });
    },
  });

  const deleteResource = (resource, skipUndoRedoAddition) => {
    if (!skipUndoRedoAddition) {
      addExecutedAction({
        name: 'deleteResource',
        params: { resource: { ...resource } },
      });
    }
    deleteResourceMutation({
      variables: {
        resourceId: resource?.id,
      },
      optimisticResponse: {
        resource: {
          delete: { ...resource },
          __typename: 'ResourceMutations',
        },
      },
    });
  };

  /**
   * UPDATE
   */
  const [
    updateResourceMutation,
    {
      data: dataUpdateResource,
      loading: loadingUpdateResource,
      error: errorUpdateResource,
    },
  ] = useMutation(UPDATE_RESOURCE, {
    context: { headers: { authorization: user?.accessToken } },
    update: (
      cache,
      {
        data: {
          resource: { update: updatedResource },
        },
      }
    ) => {
      const variables = generateGetResourcesVariables();
      const currentData = cache.readQuery({
        query: GET_RESOURCES,
        variables,
      });
      const currentResources = currentData?.resources?.data;
      const outdatedResourceIndex = currentResources.findIndex(
        (resource) => resource.id === updatedResource.id
      );

      const updatedResources = [...currentResources];
      updatedResources.splice(outdatedResourceIndex, 1, updatedResource);
      cache.writeQuery({
        query: GET_RESOURCES,
        variables,
        data: {
          resources: { data: updatedResources, __typename: 'ResourcesData' },
        },
      });
    },
  });

  const updateResource = (resource, updates, skipUndoRedoAddition) => {
    const updatableFields = [
      'description',
      'finished',
      'metadata',
      'name',
      'tags',
      'type',
      'url',
    ];
    const formattedUpdates = {};
    Object.keys(updates).forEach((field) => {
      if (updatableFields.includes(field)) {
        formattedUpdates[field] = updates[field];
      }
    });
    if (!skipUndoRedoAddition) {
      addExecutedAction({
        name: 'updateResource',
        params: { resource: { ...resource }, updates: { ...formattedUpdates } },
      });
    }
    updateResourceMutation({
      variables: {
        resourceId: resource?.id,
        updates: formattedUpdates,
      },
      optimisticResponse: {
        resource: {
          update: { ...resource, ...formattedUpdates },
          __typename: 'ResourceMutations',
        },
      },
    });
  };

  return {
    ResourcesContext,
    value: {
      selectedFilters,
      setSelectedFilters,
      selectedTags,
      setSelectedTags,
      mostRecentTags,
      selectUnselectTag,
      searchInput,
      setSearchInput,
      // resourceTypesOptions,
      // setResourceTypesOptions,
      creatorOption,
      setCreatorOption,
      // pendingOption,
      // setPendingOption,
      // finishedOption,
      // setFinishedOption,
      executedActions,

      // CREATE
      createResource,
      createResourceOutput: {
        data: dataCreateResource,
        loading: loadingCreateResource,
        error: errorCreateResource,
      },

      // READ
      getResources,
      getResourcesOutput: {
        loading: loadingGetResources,
        error: errorGetResources,
        data: dataGetResources,
      },
      filteredResources,
      useGetResources,
      useGetResourcesLazy,

      // UPDATE
      updateResource,
      updateResourceOutput: {
        data: dataUpdateResource,
        loading: loadingUpdateResource,
        error: errorUpdateResource,
      },

      // DELETE
      deleteResource,
      deleteResourceOutput: {
        data: dataDeleteResource,
        loading: loadingDeleteResource,
        error: errorDeleteResource,
      },
    },
  };
}

// function buildActiveTypesList(typesOptions) {
//   const list = [];
//   for (let typeName in typesOptions) {
//     if (typesOptions[typeName]) list.push(typeName);
//   }
//   return list;
// }
