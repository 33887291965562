// import Box from '@mui/material/Box';

import Masonry from 'react-masonry-css';

import ResourceCard from './ResourceCard';
import { memo } from 'react';

import './MasonryView.css';

const MasonryView = memo(({ indexedTags, resources }) => {
  // const resourcesMetadataRef = useRef({});
  // console.log('window.innerWidth:', window.innerWidth);
  // useEffect(() => {
  //   // const heightOutput = document.querySelector('#height');
  //   // const widthOutput = document.querySelector('#width');

  //   function resizeListener() {
  //     const height = window.innerHeight;
  //     const width = window.innerWidth;
  //     console.log(width + ' x ' + height);
  //   }

  //   window.addEventListener('resize', resizeListener);
  // }, []);
  return (
    <>
      <Masonry
        breakpointCols={{
          // default: 5,
          // 2450: 4,
          // 1500: 3,
          // // 846: 3,
          // 1150: 2,
          // // 641: 2,
          // 735: 1,
          // // 428: 1,
          default: 6,
          2380: 5,
          2026: 4,
          // 1860: 4,
          1630: 3,
          1050: 2,
          655: 1,
        }}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {resources
          ? resources.map((resource, index) => (
              <ResourceCard
                key={resource?.id}
                indexedTags={indexedTags}
                resource={resource}
                // resourcesMetadataRef={resourcesMetadataRef}
              />
            ))
          : null}
      </Masonry>
    </>
  );
});

export default MasonryView;
