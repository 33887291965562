import './Pill.css';
import { MdOutlineClose } from 'react-icons/md';
import { contrastColor } from 'contrast-color';

function Pill({
  data,
  handleClick,
  Icon,
  resource,
  resourceTags = [],
  selected,
  size = 'R',
  updateResource,
}) {
  const { color, id, name } = data;
  const fontColor = contrastColor({ bgColor: color, threshold: 180 });

  return (
    <div
      className={`pill ${selected ? 'pill-selected' : ''} ${
        size === 'S' ? 'pill-small' : 'pill-big'
      } ${resource ? '' : 'pointer pill-hover'}`}
      onClick={resource ? null : handleClick}
      style={{ backgroundColor: color, color: fontColor }}
    >
      <div
        className={`pointer pill-hover`}
        onClick={resource ? handleClick : null}
      >
        {Icon ? <Icon className="pill-icon" /> : null}
        <h2>{name}</h2>
      </div>
      {resource && (
        <MdOutlineClose
          className={`icon-button`}
          onClick={() => {
            const updatedTagIds = resourceTags.filter((tagId) => tagId !== id);
            updateResource(resource, {
              tags: updatedTagIds,
            });
          }}
          style={{ color: fontColor }}
        />
      )}
    </div>
  );
}
export default Pill;
