import { useEffect, useState } from 'react';

/**
 * Debounce a given state
 */
export default function useDebouncedState(state, ms, initialValue) {
  const [debouncedState, setDebouncedState] = useState(initialValue || null);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => setDebouncedState(state), ms);

    return () => clearTimeout(delayDebounceFn);
  }, [ms, state, setDebouncedState]);

  return debouncedState;
}
