import { gql } from '@apollo/client';

export const CREATE_RESOURCE = gql`
  mutation CreateResource($data: ResourceCreationInput!) {
    resource {
      create(data: $data) {
        id
        creator
        dateCreated
        dateUpdated
        description
        finished
        metadata
        name
        tags
        type
        url
        __typename
      }
      __typename
    }
  }
`;

export const DELETE_RESOURCE = gql`
  mutation DeleteResource($resourceId: String!) {
    resource {
      delete(id: $resourceId) {
        id
        dateCreated
        dateUpdated
        description
        finished
        metadata
        name
        tags
        type
        url
        __typename
      }
      __typename
    }
  }
`;

export const UPDATE_RESOURCE = gql`
  mutation UpdateResource(
    $resourceId: String!
    $updates: ResourceUpdatesInput!
  ) {
    resource {
      update(id: $resourceId, updates: $updates) {
        id
        creator
        dateCreated
        dateUpdated
        description
        finished
        metadata
        name
        tags
        type
        url
        __typename
      }
      __typename
    }
  }
`;

export const CREATE_TAG = gql`
  mutation CreateTag($data: TagCreationInput!) {
    tag {
      create(data: $data) {
        color
        creator
        dateCreated
        dateUpdated
        id
        name
        __typename
      }
      __typename
    }
  }
`;
