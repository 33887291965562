import { createContext } from 'react';
import useUndoRedo from '../useUndoRedo';

export const UndoRedoContext = createContext(null);

export default function useUndoRedoContext(resourcesContext) {
  const value = useUndoRedo(resourcesContext);
  return {
    UndoRedoContext,
    value,
  };
}
