import { gql } from '@apollo/client';

export const GET_RESOURCES = gql`
  query GetResources(
    $filters: ResourceFilters
    $limit: Int
    $order: ResourceOrder
  ) {
    resources(filters: $filters, limit: $limit, order: $order) {
      data {
        creator
        dateCreated
        dateUpdated
        description
        id
        finished
        metadata
        name
        tags
        type
        url
        __typename
      }
      __typename
    }
  }
`;

export const GET_TAGS = gql`
  query GetTags {
    tags {
      data {
        color
        creator
        dateCreated
        dateUpdated
        id
        name
        __typename
      }
      __typename
    }
  }
`;
