const config = {
  backend: {
    url: process.env.REACT_APP_BACKEND_URL,
  },
  corsProxyUrl: process.env.REACT_APP_CORS_PROXY_URL,
  firebase: {
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  },
  resourceTypes: ['LINK', 'READ', 'WATCH', 'STORE', 'QUICK'],
};

export default config;
