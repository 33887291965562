import './App.css';

import { createContext, useEffect, useState } from 'react';
import { auth } from './services/firebase';
import { signOut } from 'firebase/auth';
import useResourcesContext from './hooks/context/useResourcesContext';
import useTagsContext from './hooks/context/useTagsContext';

import Home from './components/Home';
import LoginCard from './components/Login/LoginCard';
import NavBar from './components/NavBar';
import Sidebar from './components/Sidebar';
import UndoRedo from './components/UndoRedo';

import useKeyboardContext from './hooks/context/useKeyboardContext';
import useUndoRedoContext from './hooks/context/useUndoRedoContext';

export const AuthContext = createContext(null);

function App() {
  const [user, setUser] = useState(null);

  const { KeyboardContext, value: keyboardValue } = useKeyboardContext();
  const { TagsContext, value: tagsValue } = useTagsContext(user);
  const { ResourcesContext, value: resourcesValue } = useResourcesContext(
    user,
    tagsValue?.indexedTags
  );
  const { UndoRedoContext, value: undoRedoValue } = useUndoRedoContext(
    resourcesValue,
    resourcesValue?.executedActions
  );

  useEffect(() => {
    auth.onAuthStateChanged((updatedUser) => {
      setUser(updatedUser);
    });
  }, []);

  return (
    // <div className={user ? 'app image-background' : 'app changing-gradient'}>
    <AuthContext.Provider value={user}>
      <KeyboardContext.Provider value={keyboardValue}>
        <TagsContext.Provider value={tagsValue}>
          <ResourcesContext.Provider value={resourcesValue}>
            <UndoRedoContext.Provider value={undoRedoValue}>
              <div className="main-container">
                <NavBar signOut={() => signOut(auth)} />
                {user ? (
                  <>
                    <Sidebar className="sidebar" />
                    <div className="content">
                      <Home />
                    </div>
                    <UndoRedo />
                  </>
                ) : (
                  <LoginCard />
                )}
              </div>
            </UndoRedoContext.Provider>
          </ResourcesContext.Provider>
        </TagsContext.Provider>
      </KeyboardContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
