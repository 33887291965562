import { useCallback, useEffect, useMemo, useState } from 'react';

export default function useUndoRedo(resourcesContext) {
  const [actionIndex, setActionIndex] = useState(-1);

  const executedActions = useMemo(
    () => resourcesContext?.executedActions,
    [resourcesContext]
  );

  const canRedo = useMemo(() => {
    return actionIndex < executedActions.length - 1;
  }, [actionIndex, executedActions]);

  const canUndo = useMemo(() => actionIndex >= 0, [actionIndex]);

  useEffect(() => {
    setActionIndex(executedActions.length - 1);
  }, [executedActions]);

  const redo = useCallback(() => {
    if (executedActions.length - 1 > actionIndex) {
      const action = executedActions[actionIndex + 1];
      console.log(`Redoing ${action?.name}`);
      const {
        name,
        params: { resource, updates },
      } = action;
      switch (name) {
        case 'createResource':
          resourcesContext.createResource(resource, true);
          break;
        case 'deleteResource':
          resourcesContext.deleteResource(resource, true);
          break;
        case 'updateResource':
          resourcesContext.updateResource(resource, updates, true);
          break;
        default:
          console.log(`Unable to execute action ${name}`);
      }

      setActionIndex((current) => current + 1);
    }
  }, [actionIndex, executedActions, resourcesContext, setActionIndex]);
  const undo = useCallback(async () => {
    if (actionIndex >= 0) {
      const action = executedActions[actionIndex];
      console.log(`Undoing ${action?.name}`);
      const {
        name,
        params: { resource },
      } = action;

      switch (name) {
        case 'createResource':
          resourcesContext.deleteResource(resource, true);
          break;
        case 'deleteResource':
          resourcesContext.createResource(resource, true);
          break;
        case 'updateResource':
          resourcesContext.updateResource(resource, resource, true);
          break;
        default:
          console.log(`Unable to execute action ${name}`);
      }

      setActionIndex((current) => current - 1);
    }
  }, [actionIndex, executedActions, resourcesContext, setActionIndex]);

  return {
    actionIndex,
    canRedo,
    canUndo,
    executedActions,
    redo,
    undo,
  };
}
