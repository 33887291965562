import './LoginCard.css';

import GoogleLogin from './GoogleLogin';

export default function LoginCard() {
  return (
    <div className="login-card centered">
      <div>
        <GoogleLogin />
      </div>
    </div>
  );
}
