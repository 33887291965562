import { useCallback, useEffect, useState } from 'react';

/**
 * Listens to single keyboard key that is pressed
 */

const keysToExclude = ['Tab', 'Space', 'CapsLock'];

export default function usePressedKey() {
  const [keysPressed, setKeysPressed] = useState([]);

  const handleKeydown = useCallback(
    (event) => {
      const key = event.key;
      if (!keysToExclude.includes(key)) {
        setKeysPressed((currentList) =>
          currentList.includes(key) ? currentList : [...currentList, key]
        );
      }
    },
    // [removeKeydownEvent]
    []
  );
  const handleKeyup = useCallback(
    (event) => {
      const key = event.key;
      setKeysPressed((currentList) => {
        let updatedList = [...currentList];
        updatedList.splice(
          currentList.findIndex((k) => k === key),
          1
        );
        return updatedList;
      });
    },
    // [setKeydownEvent]
    []
  );

  const setKeydownEvent = useCallback(
    (event) => {
      document.addEventListener('keydown', handleKeydown);
    },
    [handleKeydown]
  );
  const removeKeydownEvent = useCallback(
    (event) => {
      document.removeEventListener('keydown', handleKeydown);
    },
    [handleKeydown]
  );

  const setKeyupEvent = useCallback(
    (event) => {
      document.addEventListener('keyup', handleKeyup);
    },
    [handleKeyup]
  );
  const removeKeyupEvent = useCallback(
    (event) => {
      document.removeEventListener('keyup', handleKeyup);
    },
    [handleKeyup]
  );

  useEffect(() => {
    setKeydownEvent();
    setKeyupEvent();
    return () => {
      removeKeydownEvent();
      removeKeyupEvent();
    };
  }, [setKeydownEvent, setKeyupEvent, removeKeydownEvent, removeKeyupEvent]);

  return [keysPressed];
}
